import { useEffect, useState } from "react";
import Cookies from "js-cookie";

import Logo from "../img/logo.svg";
import LoginTop from "../img/login-top.svg";
import LoginBottom from "../img/login-bottom.svg";
import Background from "../img/background.svg";
import OpenEye from "../img/open-eye.svg";
import ClosedEye from "../img/closed-eye.svg";
// Temporarily disabled until BHI finishes Permissions
// import Google from "../img/google-g.svg";
import logout from "../util/logout";
// Temporarily disabled until BHI finishes Permissions
// import asyncAPICall from "../util/apiWrapper";

import { errorToast } from "../util/toastNotifications";

const LoginPage = (props) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [from, setFrom] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(true);

  useEffect(() => {
    setFrom(props.from || "/overview");
    logout();
  }, [props.from]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (e.target[0].value === "") {
      errorToast("Please enter an email");
      return;
    }
    if (e.target[1].value === "") {
      errorToast("Please enter a password");
      return;
    }
    setLoading(true);

    fetch("https://xytm-ohfx-fint.n7.xano.io/api:KE7MCNYf/auth/login", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        "X-Data-Source": "test",
      },
      body: JSON.stringify({
        email: e.target[0].value.toLowerCase(),
        password: e.target[1].value,
      }),
    })
      .then((res) => {
        if (res.ok) {
          setLoading(false);
          return res.json();
        } else if (res.status === 500) {
          errorToast("Invalid Email/Password");
          setLoading(false);

          return null;
        } else if (res.status === 403) {
          errorToast(
            "Your account has been deactivated. Please contact your administrator."
          );
          setLoading(false);

          return null;
        } else if (res.status === 400) {
          errorToast("Invalid Email/Password");
          setLoading(false);

          return null;
        }
      })
      .then((data) => {
        if (data) {
          Cookies.set("bearer_token", data.authToken);
          Cookies.set("auth_expires", Date.now() + 14400);
          Cookies.set("session_token", data.session_token);
          Cookies.set("session_id", data.session_id);
          Cookies.set("user_role", data.user_role);
          Cookies.set("user_name", data.user_name);
          props.history.push(from);
        }
      })
      .catch((err) => setErrorMsg(err));
  };

  // Temporarily disabled until BHI finishes Permissions
  // const handleGoogleLogin = () => {
  //   setErrorMsg("");
  //   const oathApi =
  //     process.env.NODE_ENV === "production"
  //       ? {
  //           url: "api:U0aE1wpF/oauth/google/init?redirect_uri=https%3A%2F%2Fpar.bhico.com%2Flogin%2Fauth",
  //           redirectUri: "https://par.bhico.com/login/auth",
  //         }
  //       : {
  //           url: "api:U0aE1wpF/oauth/google/init?redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Flogin%2Fauth",
  //           redirectUri: "http://localhost:3000/login/auth",
  //         };

  //   asyncAPICall(
  //     oathApi.url,
  //     "GET",
  //     { redirectUri: oathApi.redirectUri },
  //     null,
  //     (data) => {
  //       Cookies.set("auth_expires", Date.now() + 14400);
  //       window.location.replace(data.authUrl);
  //     },
  //     (err) => {
  //       console.log("Google login error:", err);
  //     },
  //     null,
  //     false
  //   );
  // };

  return (
    <div
      className={`wrapper`}
      style={{ backgroundImage: `url(${Background})` }}
    >
      <div className="svg-wrapper">
        <img src={LoginTop} alt="Top Login Border" className="login-svg" />
      </div>

      <img src={Logo} alt="BHI" className="logo" />

      <div className="login-wrapper">
        <h2>PROJECT ACTIVITY RECORD *TRAINING*</h2>
        <div className="error-message">{errorMsg}</div>

        <form onSubmit={handleSubmit} method="POST">
          <div className="input-wrapper">
            <input
              id="username"
              name="username"
              type="text"
              autoComplete="username"
              width="10px"
              placeholder="User Name"
            />
          </div>

          <div className="input-wrapper">
            <input
              id="password"
              name="password"
              maxLength={100}
              type={showPassword ? "password" : "text"}
              autoComplete="current-password"
              placeholder="Password"
            />

            <div className="open-closed-eye">
              <img
                src={showPassword ? OpenEye : ClosedEye}
                alt="open-closed-eye"
                onClick={() => setShowPassword((sp) => !sp)}
              />
            </div>
          </div>

          <button className="login-button" type="submit" disabled={loading}>
            {loading ? "" : "Login"}

            <span
              className={`loading ${loading ? "show-loading-login" : ""}`}
            />
          </button>
        </form>

        {/* Temporarily disabled until BHI finishes Permissions */}
        {/* <button
          className="google-button"
          disabled={loading}
          onClick={handleGoogleLogin}
        >
          <img className="google-g" src={Google} alt="Google G" /> Login with
          Google
        </button> */}
      </div>

      <div className="svg-wrapper">
        <img
          src={LoginBottom}
          alt="Login Bottotm Corner SVG"
          className="login-svg svg-bottom"
        />
      </div>
    </div>
  );
};

export default LoginPage;
