import { useState } from "react";
import ApprovalsEmptyData from "../pages/verification-approvals/ApprovalsEmptyData";
import PrevailingWagesTable from "./helpers/PrevailingWagesTable";
import {
  calculateSectionValues,
  calculateTotalPerDiemHours,
} from "./helpers/calculateHours";
import useAbortEffect from "./hooks/useAbortEffect";
import asyncAPICall from "../util/apiWrapper";

const VerificationPhasecode = (props) => {
  const [pwClassesByJob, setPwClassesByJob] = useState([]);
  const {
    index,
    phaseNum,
    changeDisabledSubmit,
    view,
    parData,
    combinedCheckboxValues,
    phaseCheckmarkValues,
    setPhaseCheckmarkValues,
    isDailyTicket,
    windowWidth,
  } = props;

  useAbortEffect((signal) => {
    asyncAPICall(
      `api:E3-qrfOe/vprevailingwageclassByJob?job=${parData.jobNumber}`,
      "GET",
      null,
      null,
      (data) => {
        setPwClassesByJob(() => {
          const workingPwClasses = [
            { Class: "default", Description: "Non Prevailing Wage", id: 0 },
          ];
          workingPwClasses.push(...data);
          return workingPwClasses;
        });
      },
      (err) => {
        console.error(err);
      },
      signal
    );
  }, []);

  const alphabetizedEmployees = [
    ...parData.phaseCodes[phaseNum].laborData,
  ].sort((firstEmployee, secondEmployee) =>
    firstEmployee.name > secondEmployee.name ? 1 : -1
  );

  return (
    <div className="tables-wrapper">
      <div className="section-content">
        <label className="checkbox container phase-code">
          {view !== "approved" && view !== "searched" && (
            <>
              {combinedCheckboxValues ? (
                <input
                  className="checkbox"
                  checked={
                    combinedCheckboxValues[index]
                      ? combinedCheckboxValues[index].phase
                      : true
                  }
                  type="checkbox"
                  onChange={() => {
                    if (setPhaseCheckmarkValues) {
                      setPhaseCheckmarkValues((prev) => {
                        const updated = { ...prev };
                        updated[phaseNum] = !prev[phaseNum];
                        return updated;
                      });
                    }
                    changeDisabledSubmit("phase", index);
                  }}
                />
              ) : phaseCheckmarkValues ? (
                <input
                  className="checkbox"
                  defaultChecked={phaseCheckmarkValues[phaseNum]}
                  type="checkbox"
                  onClick={() => {
                    if (setPhaseCheckmarkValues) {
                      setPhaseCheckmarkValues((prev) => {
                        const updated = { ...prev };
                        updated[phaseNum] = !prev[phaseNum];
                        return updated;
                      });
                    }
                    changeDisabledSubmit("phase", index);
                  }}
                  disabled={isDailyTicket}
                />
              ) : (
                <input
                  className="checkbox"
                  type="checkbox"
                  onChange={() => {
                    if (setPhaseCheckmarkValues) {
                      setPhaseCheckmarkValues((prev) => {
                        const updated = { ...prev };
                        updated[phaseNum] = !prev[phaseNum];
                        return updated;
                      });
                    }
                    changeDisabledSubmit("phase", index);
                  }}
                  disabled={isDailyTicket}
                />
              )}
              <span
                id={isDailyTicket ? "checkbox-disabled" : ""}
                className="checkmark"
              />
            </>
          )}
        </label>
        <div className="table">
          <h1>Phase Code: {parData.phaseCodes[phaseNum].phaseDescription}</h1>
        </div>
      </div>

      {parData.phaseCodes[phaseNum].phaseNote?.length > 0 && (
        <div className="section-content">
          <label className="checkbox container hidden">
            <span className="checkmark" />
          </label>

          <div className="table table-notes">
            <div className="phase-note">
              <div className="header">Phase Note</div>
              {parData.phaseCodes[phaseNum].phaseNote}
            </div>
          </div>
        </div>
      )}

      {parData.phaseCodes[phaseNum].laborData.length > 0 && (
        <div className="section-content">
          <label className="checkbox container hidden">
            <span className="checkmark" />
          </label>

          {windowWidth <= 650 ? (
            <div className="table-wrapper">
              {phaseNum !== "012130-00-00" ? (
                parData.jobCertified !== "Y" ? (
                  <table>
                    <tbody>
                      <tr>
                        <th className="vertical-header">
                          <span>Employee Name</span>
                        </th>
                        {alphabetizedEmployees.reduce(
                          (acc, currentVal, idx) => {
                            const data = (
                              <td
                                className={idx === 0 ? "first-column" : ""}
                                key={currentVal.id + "1"}
                              >
                                {currentVal.name}
                              </td>
                            );
                            acc.push(data);
                            return acc;
                          },
                          []
                        )}
                      </tr>
                      <tr>
                        <th className="vertical-header">
                          <span>Employee Number</span>
                        </th>
                        {alphabetizedEmployees.reduce(
                          (acc, currentVal, idx) => {
                            const data = (
                              <td
                                className={idx === 0 ? "first-column" : ""}
                                key={currentVal.id + "2"}
                              >
                                {currentVal.id}
                              </td>
                            );
                            acc.push(data);
                            return acc;
                          },
                          []
                        )}
                      </tr>
                      <tr>
                        <th className="vertical-header">
                          <span>Phase</span>
                        </th>
                        {alphabetizedEmployees.reduce(
                          (acc, currentVal, idx) => {
                            const data = (
                              <td
                                className={idx === 0 ? "first-column" : ""}
                                key={currentVal.id + "3"}
                              >
                                {phaseNum}
                              </td>
                            );
                            acc.push(data);
                            return acc;
                          },
                          []
                        )}
                      </tr>
                      <tr>
                        <th className="vertical-header">
                          <span>Hours</span>
                        </th>
                        {alphabetizedEmployees.reduce(
                          (acc, currentVal, idx) => {
                            const data = (
                              <td
                                className={idx === 0 ? "first-column" : ""}
                                key={currentVal.id + "4"}
                              >
                                {currentVal.hoursWorked}
                              </td>
                            );

                            acc.push(data);
                            return acc;
                          },
                          []
                        )}
                      </tr>
                      <tr>
                        <th className="vertical-header">
                          <span>Note</span>
                        </th>
                        {alphabetizedEmployees.reduce(
                          (acc, currentVal, idx) => {
                            const data = (
                              <td
                                className={idx === 0 ? "first-column" : ""}
                                key={currentVal.id + "7"}
                              >
                                {currentVal.laborNotes}
                              </td>
                            );
                            acc.push(data);
                            return acc;
                          },
                          []
                        )}
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <PrevailingWagesTable
                    parFormDataState={parData}
                    phaseNum={phaseNum}
                    pwClassesByJob={pwClassesByJob}
                    editInfo={false}
                  />
                )
              ) : (
                <table>
                  <tbody>
                    <tr>
                      <th className="vertical-header">
                        <span>Employee Name</span>
                      </th>
                      {alphabetizedEmployees.reduce((acc, currentVal, idx) => {
                        const data = (
                          <td
                            className={idx === 0 ? "first-column" : ""}
                            key={currentVal.id + "1"}
                          >
                            {currentVal.name}
                          </td>
                        );
                        acc.push(data);
                        return acc;
                      }, [])}
                    </tr>
                    <tr>
                      <th className="vertical-header">
                        <span>Employee Number</span>
                      </th>
                      {alphabetizedEmployees.reduce((acc, currentVal, idx) => {
                        const data = (
                          <td
                            className={idx === 0 ? "first-column" : ""}
                            key={currentVal.id + "2"}
                          >
                            {currentVal.id}
                          </td>
                        );
                        acc.push(data);
                        return acc;
                      }, [])}
                    </tr>
                    <tr>
                      <th className="vertical-header">
                        <span>Phase</span>
                      </th>
                      {alphabetizedEmployees.reduce((acc, currentVal, idx) => {
                        const data = (
                          <td
                            className={idx === 0 ? "first-column" : ""}
                            key={currentVal.id + "3"}
                          >
                            {phaseNum}
                          </td>
                        );
                        acc.push(data);
                        return acc;
                      }, [])}
                    </tr>
                    <tr>
                      <th className="vertical-header">
                        <span>Hours</span>
                      </th>
                      {alphabetizedEmployees.reduce((acc, currentVal, idx) => {
                        const data = (
                          <td
                            className={idx === 0 ? "first-column" : ""}
                            key={currentVal.id + "4"}
                          >
                            {currentVal.combinedPhaseTime}
                          </td>
                        );

                        acc.push(data);
                        return acc;
                      }, [])}
                    </tr>
                    <tr>
                      <th className="vertical-header">
                        <span>Per Diem</span>
                      </th>
                      {alphabetizedEmployees.reduce((acc, currentVal, idx) => {
                        const data = (
                          <td
                            className={idx === 0 ? "first-column" : ""}
                            key={currentVal.id + "5"}
                          >
                            {currentVal.perDiem ? "Y" : "N"}
                          </td>
                        );
                        acc.push(data);
                        return acc;
                      }, [])}
                    </tr>
                    <tr>
                      <th className="vertical-header">
                        <span>Per Diem Amount</span>
                      </th>
                      {alphabetizedEmployees.reduce((acc, currentVal, idx) => {
                        const data = (
                          <td
                            className={idx === 0 ? "first-column" : ""}
                            key={currentVal.id + "6"}
                          >
                            {currentVal.perDiemAmount}
                          </td>
                        );
                        acc.push(data);
                        return acc;
                      }, [])}
                    </tr>
                    <tr>
                      <th className="vertical-header">
                        <span>Note</span>
                      </th>
                      {alphabetizedEmployees.reduce((acc, currentVal, idx) => {
                        const data = (
                          <td
                            className={idx === 0 ? "first-column" : ""}
                            key={currentVal.id + "7"}
                          >
                            {currentVal.laborNotes}
                          </td>
                        );
                        acc.push(data);
                        return acc;
                      }, [])}
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          ) : (
            <div className="table-wrapper">
              {phaseNum !== "012130-00-00" ? (
                parData.jobCertified !== "Y" ? (
                  <table cellSpacing={0}>
                    <thead>
                      <tr>
                        <th>Employee Name</th>
                        <th>Employee Number</th>
                        <th>Phase</th>
                        <th>Hours</th>
                        <th>Note</th>
                      </tr>
                    </thead>

                    <tbody>
                      {alphabetizedEmployees.map((employee) => {
                        return (
                          <tr key={employee.id}>
                            <td>{employee.name}</td>
                            <td>{employee.id}</td>
                            <td>{phaseNum}</td>
                            <td>{employee.hoursWorked}</td>
                            <td>{employee.laborNotes}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <PrevailingWagesTable
                    parFormDataState={parData}
                    phaseNum={phaseNum}
                    editInfo={false}
                    pwClassesByJob={pwClassesByJob}
                  />
                )
              ) : (
                <table cellSpacing={0}>
                  <thead>
                    <tr>
                      <th>Employee Name</th>
                      <th>Employee Number</th>
                      <th>Phase</th>
                      <th>Hours</th>
                      <td>Per Diem</td>
                      <td>Per Diem Amount</td>
                      <th>Note</th>
                    </tr>
                  </thead>

                  <tbody>
                    {alphabetizedEmployees.map((employee) => {
                      return (
                        <tr key={employee.id}>
                          <td>{employee.name}</td>
                          <td>{employee.id}</td>
                          <td>{phaseNum}</td>
                          <td>{employee.combinedPhaseTime}</td>
                          <td>{employee.perDiem ? "Y" : "N"}</td>
                          <td>{employee.perDiemAmount}</td>
                          <td>{employee.laborNotes}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          )}

          {parData.phaseCodes[phaseNum].laborData.length > 0 && (
            <h3 className="par-phase-total">
              Total Employee Hours:{" "}
              {phaseNum === "012130-00-00"
                ? calculateTotalPerDiemHours(
                    parData.phaseCodes[phaseNum].laborData,
                    parData
                  )
                : calculateSectionValues(
                    parData.phaseCodes[phaseNum].laborData,
                    "hoursWorked"
                  )}
            </h3>
          )}
        </div>
      )}

      {phaseNum !== "012130-00-00" &&
        parData.phaseCodes[phaseNum].equipmentData.length > 0 && (
          <div className="section-content">
            <label className="checkbox container hidden">
              <span className="checkmark" />
            </label>

            {windowWidth <= 650 ? (
              <div className="table-wrapper">
                <table>
                  <tbody>
                    <tr>
                      <th className="vertical-header">
                        <span>Equipment Number & Description</span>
                      </th>
                      {parData.phaseCodes[phaseNum].equipmentData.reduce(
                        (acc, currentVal, idx) => {
                          const data = (
                            <td
                              className={idx === 0 ? "first-column" : ""}
                              key={currentVal.equipNum + "1"}
                            >
                              {currentVal.equipNum} {currentVal.description}
                            </td>
                          );
                          acc.push(data);
                          return acc;
                        },
                        []
                      )}
                    </tr>
                    <tr>
                      <th className="vertical-header">
                        <span>Phase</span>
                      </th>
                      {parData.phaseCodes[phaseNum].equipmentData.reduce(
                        (acc, currentVal, idx) => {
                          const data = (
                            <td
                              className={idx === 0 ? "first-column" : ""}
                              key={currentVal.equipNum + "2"}
                            >
                              {phaseNum}
                            </td>
                          );
                          acc.push(data);
                          return acc;
                        },
                        []
                      )}
                    </tr>
                    <tr>
                      <th className="vertical-header">
                        <span>Hours</span>
                      </th>
                      {parData.phaseCodes[phaseNum].equipmentData.reduce(
                        (acc, currentVal, idx) => {
                          const data = (
                            <td
                              className={idx === 0 ? "first-column" : ""}
                              key={currentVal.equipNum + "3"}
                            >
                              {currentVal.operatedHours}
                            </td>
                          );
                          acc.push(data);
                          return acc;
                        },
                        []
                      )}
                    </tr>
                    <tr>
                      <th className="vertical-header">
                        <span>Note</span>
                      </th>
                      {parData.phaseCodes[phaseNum].equipmentData.reduce(
                        (acc, currentVal, idx) => {
                          const data = (
                            <td
                              className={idx === 0 ? "first-column" : ""}
                              key={currentVal.equipNum + "3"}
                            >
                              {currentVal.equipNote}
                            </td>
                          );

                          acc.push(data);
                          return acc;
                        },
                        []
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="table-wrapper">
                <table cellSpacing={0}>
                  <thead>
                    <tr>
                      <th>Equipment Number & Description</th>
                      <th>Phase</th>
                      <th>Hours</th>
                      <th>Note</th>
                    </tr>
                  </thead>

                  <tbody>
                    {parData.phaseCodes[phaseNum].equipmentData.map((item) => {
                      return (
                        <tr key={item.equipNum}>
                          <td>
                            {item.equipNum} {item.description}
                          </td>
                          <td>{phaseNum}</td>
                          <td>{item.operatedHours}</td>
                          <td>{item.equipNote}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}

            {parData.phaseCodes[phaseNum].equipmentData.length > 0 && (
              <h3 className="par-phase-total">
                Total Equipment Hours:{" "}
                {calculateSectionValues(
                  parData.phaseCodes[phaseNum].equipmentData,
                  "operatedHours"
                )}
              </h3>
            )}
          </div>
        )}

      {phaseNum !== "012130-00-00" &&
        parData.phaseCodes[phaseNum].progressEntry.length > 0 && (
          <div className="section-content">
            <label className="checkbox container hidden">
              <span className="checkmark" />
            </label>

            {windowWidth <= 650 ? (
              <div className="table-wrapper">
                <table>
                  <tbody>
                    <tr>
                      <th className="vertical-header">
                        <span>Phase Code</span>
                      </th>
                      {parData.phaseCodes[phaseNum].progressEntry.reduce(
                        (acc, currentVal, idx) => {
                          const data = (
                            <td
                              className={idx === 0 ? "first-column" : ""}
                              key={currentVal.id + "1"}
                            >
                              {currentVal.phase}
                            </td>
                          );
                          acc.push(data);
                          return acc;
                        },
                        []
                      )}
                    </tr>
                    <tr>
                      <th className="vertical-header">
                        <span>Phase Description</span>
                      </th>
                      {parData.phaseCodes[phaseNum].progressEntry.reduce(
                        (acc, currentVal, idx) => {
                          const data = (
                            <td
                              className={idx === 0 ? "first-column" : ""}
                              key={currentVal.id + "2"}
                            >
                              {currentVal.description}
                            </td>
                          );
                          acc.push(data);
                          return acc;
                        },
                        []
                      )}
                    </tr>
                    <tr>
                      <th className="vertical-header">
                        <span>Current Estimated Units</span>
                      </th>
                      {parData.phaseCodes[phaseNum].progressEntry.reduce(
                        (acc, currentVal, idx) => {
                          const data = (
                            <td
                              className={idx === 0 ? "first-column" : ""}
                              key={currentVal.id + "3"}
                            >
                              {currentVal.currentEstimatedUnits}
                            </td>
                          );
                          acc.push(data);
                          return acc;
                        },
                        []
                      )}
                    </tr>
                    <tr>
                      <th className="vertical-header">
                        <span>UOM</span>
                      </th>
                      {parData.phaseCodes[phaseNum].progressEntry.reduce(
                        (acc, currentVal, idx) => {
                          const data = (
                            <td
                              className={idx === 0 ? "first-column" : ""}
                              key={currentVal.id + "4"}
                            >
                              {currentVal.uom}
                            </td>
                          );
                          acc.push(data);
                          return acc;
                        },
                        []
                      )}
                    </tr>
                    <tr>
                      <th className="vertical-header">
                        <span>JTD Progress</span>
                      </th>
                      {parData.phaseCodes[phaseNum].progressEntry.reduce(
                        (acc, currentVal, idx) => {
                          const data = (
                            <td
                              className={idx === 0 ? "first-column" : ""}
                              key={currentVal.id + "5"}
                            >
                              {currentVal.jtdProgress}
                            </td>
                          );
                          acc.push(data);
                          return acc;
                        },
                        []
                      )}
                    </tr>
                    <tr>
                      <th className="vertical-header">
                        <span>PAR Daily Progress</span>
                      </th>
                      {parData.phaseCodes[phaseNum].progressEntry.reduce(
                        (acc, currentVal, idx) => {
                          const data = (
                            <td
                              className={idx === 0 ? "first-column" : ""}
                              key={currentVal.id + "6"}
                            >
                              {currentVal.dcrProgress}
                            </td>
                          );
                          acc.push(data);
                          return acc;
                        },
                        []
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="table-wrapper">
                <table cellSpacing={0}>
                  <thead>
                    <tr>
                      <th>Phase Code</th>
                      <th>Phase Description</th>
                      <th>Current Estimated Units</th>
                      <th>UOM</th>
                      <th>JTD Progress</th>
                      <th>PAR Daily Progress</th>
                    </tr>
                  </thead>

                  <tbody>
                    {parData.phaseCodes[phaseNum].progressEntry.map((entry) => {
                      return (
                        <tr key={entry.id}>
                          <td>{entry.phase}</td>
                          <td>{entry.description}</td>
                          <td>{entry.currentEstimatedUnits}</td>
                          <td>{entry.uom}</td>
                          <td>{entry.jtdProgress}</td>
                          <td>{entry.dcrProgress}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}

      <ApprovalsEmptyData parData={parData.phaseCodes[phaseNum]} />
    </div>
  );
};

export default VerificationPhasecode;
