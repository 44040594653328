import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import asyncAPICall from "../../../util/apiWrapper";
import useAbortEffect from "../../../components/hooks/useAbortEffect";

import { checkSameDayPar } from "../../../components/helpers/checkSameDayPar";
import { errorToast } from "../../../util/toastNotifications";
import { baseParFormData } from "../../../components/reducer/actions";
import { ParDataContext } from "../../../defaultContainer";

export default function useApprovalFunctions() {
  const { parFormDataState, parDispatch } = useContext(ParDataContext);
  const history = useHistory();
  const pathRef = window.location.href;
  const pathRefArray = pathRef.split("/", 3);
  const dynamicPath = pathRefArray.join("/");
  const approvalPath = `/par-verification/confirm+`;
  const printPath = `/select-print/`;
  const approvalLink = `${dynamicPath}${approvalPath}`;
  const printLink = `${dynamicPath}${printPath}`;

  const [pmEmail, setPmEmail] = useState("");

  useAbortEffect(
    (signal) => {
      asyncAPICall(
        `api:KE7MCNYf/vprojectswithid_by_Project?project=${parFormDataState.jobNumber}`,
        "GET",
        null,
        null,
        (data) => {
          setPmEmail(data[0].PM_Email);
        },
        (err) => {
          if (!signal.aborted) {
            console.log("Getting PM Email Error", err);
          }
        },
        signal
      );
    },
    [parFormDataState.jobNumber]
  );

  const submitFullPar = async (
    parData,
    approverEmail,
    setButton,
    pfs,
    foreman,
    sendEmail
  ) => {
    setButton(true);
    let sameDayValue = false;

    if (parData.dcrNumber === "") {
      sameDayValue = await checkSameDayPar(
        parData.jobNumber,
        parData.date,
        parData.foreman
      );
    }

    if (sameDayValue) {
      const errorToastPromise = new Promise((resolve, reject) => {
        resolve(
          errorToast(
            `A PAR has already been created for  ${parData.jobNumber} on ${parData.date}. SAMEDAY`
          )
        );
      });

      errorToastPromise.finally(() => {
        setButton(false);
        history.push("/new-par");
      });
    } else {
      const getUrl = new Promise((resolve, reject) => {
        let submission;
        if ("id" in parData) {
          asyncAPICall(
            `api:W2oHkD04/partblmain/checkForId?id=${parData.id}`,
            "GET",
            null,
            null,
            (data) => {
              if (data.length > 0) {
                const body = {
                  ...parData,
                  dcrStatus: "Pending",
                };

                body.statusComments?.forEach((sC, idx) => {
                  body.statusComments[idx].status = "Pending";
                });
                submission = {
                  url: `api:W2oHkD04/partblmain/resubmissionById?id=${parData.id}`,
                  submitPar: body,
                };
              } else {
                submission = {
                  url: "api:W2oHkD04/partblmain",
                  submitPar: parData,
                };
              }

              resolve(submission);
            },
            (err) => {
              console.log("error on fetching existing PAR", err);
              reject(err);
            }
          );
        } else {
          submission = { url: "api:W2oHkD04/partblmain", submitPar: parData };
          resolve(submission);
        }
      });
      getUrl
        .then((submission) => {
          asyncAPICall(
            submission.url,
            "POST",
            submission.submitPar,
            null,
            (data) => {
              localStorage.clear();
              localStorage.setItem(
                "checkboxValues",
                JSON.stringify({
                  issues: true,
                  injuries: true,
                  subcontractor: true,
                  incidents: true,
                  material: true,
                  timeMaterials: true,
                  overnight: false,
                })
              );

              parDispatch({
                type: "setParForm",
                form: baseParFormData,
              });

              history.push("/overview");
            },
            (err) => {
              console.log("sending PAR error: ", err);
            },
            null,
            true
          );
        })
        .catch(console.error)
        .finally(() => setButton(false));
    }
  };

  const parApprovalStatus = (status, parData, setButton) => {
    setButton(true);
    const body = {
      ...parData,
      dcrStatus: status,
    };

    body.statusComments?.forEach((sC, idx) => {
      if (status === "Reject") {
        if (idx === body.statusComments.length - 1) {
          body.statusComments[idx].status = status;
        }
      } else {
        body.statusComments[idx].status = status;
      }
    });

    asyncAPICall(
      `api:W2oHkD04/partblmain/resubmissionById?id=${parData.id}`,
      "POST",
      body,
      (res) => {
        if (res.ok) {
          history.push("/approvals");
        } else {
          errorToast(
            `Unable to submit PAR for ${parData.jobNumber} on ${parData.date}`
          );
        }
      },
      null,
      (err) => {
        console.error("Sending Updated Status Error", err);
      },
      null,
      true
    );
  };

  return {
    submitFullPar,
    parApprovalStatus,
  };
}
