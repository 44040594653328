import Cookies from "js-cookie";
import logout from "./logout";
export const api_host = process.env.REACT_APP_API_HOST
  ? process.env.REACT_APP_API_HOST
  : "localhost";
export const api_port = process.env.REACT_APP_API_PORT
  ? process.env.REACT_APP_API_PORT
  : "5000";

export default function asyncAPICall(
  api_endpoint,
  method = "GET",
  body = {},
  response_callback_method = null,
  data_callback_method = null,
  catch_callback_method = null,
  signal = null,
  require_bearer_token = true,
  headers = null,
  doNotStringifyBody = false
) {
  const bearer_token = Cookies.get("bearer_token");

  if (!headers) {
    headers = {
      "content-type": "application/json",
      "X-Data-Source": "test",
    };
  }

  if (require_bearer_token) {
    if (!bearer_token || bearer_token === "") {
      console.log("Auth Token Required");
      return false;
    }

    headers["Authorization"] = `Bearer ${bearer_token}`;

    let expiration = Cookies.get("auth_expires");
    if (Date.parse(expiration) < Date.now()) {
      console.log("Expired Auth Token");
      return false;
    }
  }

  let payload = { method: method, headers: headers };

  if (method === "POST") {
    if (doNotStringifyBody) {
      payload.body = body;
    } else {
      payload.body = JSON.stringify(body);
    }
  }
  if (signal) {
    payload.signal = signal;
  }
  let response_function = (response) => {
    if (response.ok) {
      return response.json();
    } else if (response.status === 403 || response.status === 401) {
      logout();
    }
    let error = new Error(response.statusText);
    error.response = response;
    throw error;
  };
  if (response_callback_method) {
    response_function = response_callback_method;
  }
  let data_function = (data) => {};
  if (data_callback_method) {
    data_function = data_callback_method;
  }
  let catch_function = (error) => console.log(error);
  if (catch_callback_method) {
    catch_function = catch_callback_method;
  }
  // TODO : Replace host and port with config variables
  fetch(`https://xytm-ohfx-fint.n7.xano.io/${api_endpoint}`, payload)
    .then((response) => response_function(response))
    .then((response) => data_function(response))
    .catch((response) => catch_function(response));

  return true;
}
export function awaitAPICall(
  api_endpoint,
  method = "GET",
  body = {},
  response_callback_method = null,
  data_callback_method = null,
  catch_callback_method = null,
  signal = null,
  require_bearer_token = true
) {
  let bearer_token = Cookies.get("bearer_token");
  let expiration = Cookies.get("auth_expires");
  if (Date.parse(expiration) < Date.now()) {
    console.log("Expired Auth Token");
    return false;
  }
  if (!require_bearer_token) {
    bearer_token = "not_required";
  }
  if (bearer_token) {
    let payload = {
      method: method,
      headers: {
        "content-type": "application/json",
        bearer_token: bearer_token,
        "X-Data-Source": "test",
      },
    };
    if (method === "POST") {
      payload.body = JSON.stringify(body);
    }
    if (signal) {
      payload.signal = signal;
    }
    let response_function = (response) => {
      if (response.ok) {
        return response.json();
      } else if (response.status === 403 || response.status === 401) {
        logout();
      }
      let error = new Error(response.statusText);
      error.response = response;
      throw error;
    };
    if (response_callback_method) {
      response_function = response_callback_method;
    }
    let data_function = (data) => {};
    if (data_callback_method) {
      data_function = data_callback_method;
    }
    let catch_function = (error) => console.log(error);
    if (catch_callback_method) {
      catch_function = catch_callback_method;
    }
    let fetchFromAPI = async () => {
      try {
        let response = await fetch(
          `https://xytm-ohfx-fint.n7.xano.io/${api_endpoint}`,
          payload
        );
        let results = await response_function(response);
        await data_function(results);
      } catch (error) {
        catch_function(error);
        return false;
      }
    };
    fetchFromAPI();
    return true;
  } else {
    return false;
  }
}
