import { calculateTotalParValues } from "./helpers/calculateHours";
import { getTotalHours } from "./helpers/totalHours";

const VerificationTotals = ({ formData, windowWidth }) => {
  const laborTotal = calculateTotalParValues(formData.phaseCodes, "laborData");
  const equipmentTotal = calculateTotalParValues(
    formData.phaseCodes,
    "equipmentData"
  );

  const renderTotals = (type) => {
    const totalsData = [];
    const idNums = [];

    Object.keys(formData.phaseCodes).forEach((phaseCode) => {
      const formSection =
        type === "equipment"
          ? formData.phaseCodes[phaseCode].equipmentData
          : formData.phaseCodes[phaseCode].laborData;

      for (const item of formSection) {
        const id = type === "equipment" ? item.equipNum : item.id;
        const description = type === "equipment" ? item.description : item.name;

        if (!idNums.includes(id)) {
          idNums.push(id);
          totalsData.push([id, description]);
        }
      }
    });

    const alphabetizedItems = [...totalsData].sort(
      (firstEmployee, secondEmployee) =>
        firstEmployee[1] > secondEmployee[1] ? 1 : -1
    );

    return windowWidth <= 650
      ? type === "equipment"
        ? equipmentTotal > 0 && (
            <tbody>
              <tr>
                <th className="vertical-header">
                  <span>Equipment Number & Description</span>
                </th>
                {alphabetizedItems.reduce((acc, currentVal, idx) => {
                  const data = (
                    <td
                      className={idx === 0 ? "first-column" : ""}
                      key={currentVal[0] + "1"}
                    >
                      {currentVal[0]} {currentVal[1]}
                    </td>
                  );
                  acc.push(data);
                  return acc;
                }, [])}
              </tr>
              <tr>
                <th className="vertical-header">
                  <span>Hours</span>
                </th>
                {alphabetizedItems.reduce((acc, currentVal, idx) => {
                  const data = (
                    <td
                      className={idx === 0 ? "first-column" : ""}
                      key={currentVal[0] + "2"}
                    >
                      {getTotalHours(currentVal[0], formData, "equipment")}
                    </td>
                  );
                  acc.push(data);
                  return acc;
                }, [])}
              </tr>
            </tbody>
          )
        : laborTotal > 0 && (
            <tbody>
              <tr>
                <th className="vertical-header">
                  <span>Employee Name</span>
                </th>
                {alphabetizedItems.reduce((acc, currentVal, idx) => {
                  const data = (
                    <td
                      className={idx === 0 ? "first-column" : ""}
                      key={currentVal[0] + "1"}
                    >
                      {currentVal[1]}
                    </td>
                  );
                  acc.push(data);
                  return acc;
                }, [])}
              </tr>
              <tr>
                <th className="vertical-header">
                  <span>Employee Number</span>
                </th>
                {alphabetizedItems.reduce((acc, currentVal, idx) => {
                  const data = (
                    <td
                      className={idx === 0 ? "first-column" : ""}
                      key={currentVal[0] + "2"}
                    >
                      {currentVal[0]}
                    </td>
                  );
                  acc.push(data);
                  return acc;
                }, [])}
              </tr>
              <tr>
                <th className="vertical-header">
                  <span>Hours</span>
                </th>
                {alphabetizedItems.reduce((acc, currentVal, idx) => {
                  const data = (
                    <td
                      className={idx === 0 ? "first-column" : ""}
                      key={currentVal[0] + "3"}
                    >
                      {getTotalHours(currentVal[0], formData, "labor")}
                    </td>
                  );
                  acc.push(data);
                  return acc;
                }, [])}
              </tr>
            </tbody>
          )
      : alphabetizedItems.map((item) => {
          return type === "equipment" ? (
            <tbody key={item[0]}>
              <tr>
                <td>
                  {item[0]} {item[1]}
                </td>
                <td>{getTotalHours(item[0], formData, "equipment")}</td>
              </tr>
            </tbody>
          ) : (
            <tbody key={item[0]}>
              <tr>
                <td>{item[1]}</td>
                <td>{item[0]}</td>
                <td>{getTotalHours(item[0], formData, "labor")}</td>
              </tr>
            </tbody>
          );
        });
  };

  return (
    <div className="tables">
      <h1>Total Hours</h1>
      <div className="outer-wrapper">
        <div className="table-wrapper">
          <table cellSpacing={0}>
            {laborTotal > 0 && (
              <thead>
                <tr>
                  <th>Employee Name</th>
                  <th>Employee Number</th>
                  <th>Hours</th>
                </tr>
              </thead>
            )}
            {renderTotals("labor")}
          </table>
        </div>
      </div>

      {laborTotal > 0 ? (
        <h3 className="par-phase-total">
          Daily Total Employee Hours: {laborTotal}
        </h3>
      ) : (
        <h3 className="par-phase-total-labor">No Labor</h3>
      )}

      <div className="outer-wrapper">
        <div className="table-wrapper">
          <table cellSpacing={0}>
            {windowWidth > 650 &&
              (equipmentTotal > 0 ? (
                <thead>
                  <tr>
                    <th>Equipment Number & Description</th>
                    <th>Hours</th>
                  </tr>
                </thead>
              ) : null)}
            {renderTotals("equipment")}
          </table>
        </div>
      </div>

      {equipmentTotal > 0 ? (
        <h3 className="par-phase-total-equipment">
          Daily Total Equipment Hours: {equipmentTotal}
        </h3>
      ) : (
        <h3 className="par-phase-total">No Equipment</h3>
      )}
    </div>
  );
};

export default VerificationTotals;
